<template>
    <div class="pageContol listWrap templateList">
        <div class="framePage">
            <!-- 面包屑 -->
            <div class="framePage-title">
                <span>
                    <em>当前位置：</em>
                    <a href="javascript:;">客户中心</a>
                    <i>></i>
                    <a href="javascript:;" @click="init" class="cur-a">客户入驻</a>
                </span>
            </div>
            <!-- 按钮区域 -->
            <div class="framePage-body">
                 <div class="framePage-scroll">
                    <div class="ovy-a">
                        <el-tabs type="card" v-model="activeTab">
                            <el-tab-pane label="慕课合作" name="first">
                                <el-table
                                    :data="tableData1"
                                    :height="tableHeight"
                                    size="small"
                                    tooltip-effect="dark"
                                    style="width: 100%"
                                    :header-cell-style="tableHeader"
                                    stripe
                                    >
                                    <el-table-column
                                        label="入驻类型"
                                        align="left"
                                        show-overflow-tooltip
                                        prop="joinType"
                                    >
                                        <template v-slot="{row}">
                                            {{row.joinType=="10"?"机构入驻":"名师入驻"}}
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        label="机构名"
                                        align="left"
                                        show-overflow-tooltip
                                        prop="companyName"
                                    />
                                    <el-table-column
                                        label="姓名"
                                        align="left"
                                        show-overflow-tooltip
                                        prop="name"
                                    />
                                    <el-table-column
                                        label="联系电话"
                                        align="left"
                                        show-overflow-tooltip
                                        prop="mobile"
                                    />
                                    <el-table-column
                                        label="提交时间"
                                        align="left"
                                        show-overflow-tooltip
                                        prop="createTime"
                                    />
                                    <Empty slot="empty" />
                                </el-table>
                                <PageNum class="pagenum" :apiData="pagination1" @getData="currentChange1" @sizeChange="sizeChange1"></PageNum>
                            </el-tab-pane>
                            <el-tab-pane label="认定服务" name="second">
                                <el-table
                                    :data="tableData2"
                                    :height="tableHeight"
                                    size="small"
                                    tooltip-effect="dark"
                                    style="width: 100%"
                                    :header-cell-style="tableHeader"
                                    stripe
                                    >
                                    <el-table-column
                                        label="机构名"
                                        align="left"
                                        show-overflow-tooltip
                                        prop="companyName"
                                    />
                                    <el-table-column
                                        label="姓名"
                                        align="left"
                                        show-overflow-tooltip
                                        prop="contactsName"
                                    />
                                    <el-table-column
                                        label="联系电话"
                                        align="left"
                                        show-overflow-tooltip
                                        prop="contactsMobile"
                                    />
                                    <el-table-column
                                        label="提交时间"
                                        align="left"
                                        show-overflow-tooltip
                                        prop="createTime"
                                    />
                                    <Empty slot="empty" />
                                </el-table>
                                <PageNum class="pagenum" :apiData="pagination2" @getData="currentChange2" @sizeChange="sizeChange2"></PageNum>
                            </el-tab-pane>
                            <el-tab-pane label="人力资源" name="three">
                                <el-table
                                    :data="tableData3"
                                    :height="tableHeight"
                                    size="small"
                                    tooltip-effect="dark"
                                    style="width: 100%"
                                    :header-cell-style="tableHeader"
                                    stripe
                                    >
                                    <el-table-column
                                        label="企业名称"
                                        align="left"
                                        show-overflow-tooltip
                                        prop="resourceName"
                                    />
                                    <el-table-column
                                        label="姓名"
                                        align="left"
                                        show-overflow-tooltip
                                        prop="resourceFullName"
                                    />
                                    <el-table-column
                                        label="联系电话"
                                        align="left"
                                        show-overflow-tooltip
                                        prop="resourceMobile"
                                    />
                                    <el-table-column
                                        label="提交时间"
                                        align="left"
                                        show-overflow-tooltip
                                        prop="createTime"
                                    />
                                    <Empty slot="empty" />
                                </el-table>
                                <PageNum class="pagenum" :apiData="pagination3" @getData="currentChange3" @sizeChange="sizeChange3"></PageNum>
                            </el-tab-pane>
                        </el-tabs>
                    </div>
                 </div>
            </div>
        </div>
    </div>
</template>

<script>
    import PageNum from "@/components/PageNum.vue";
    import List from "@/mixins/List";
    import Empty from "@/components/Empty.vue";
    export default {
        name:"userFeedback",
        mixins:[List],
        components:{
            Empty,
            PageNum
        },
        created(){
            this.getTableData1();
            this.getTableData2();
            this.getTableData3();
        },
        data(){
            return{
                // el-tabs激活的当前项
                activeTab:"first",
                // 慕课合作数据
                tableData1:[],
                // 认证服务数据
                tableData2:[],
                // 人力资源数据
                tableData3:[],
                // 分页器数据(慕课合作)
                pagination1:{
                    total: 0,
                    size: 10,
                    current: 1
                },
                // （认证服务）
                pagination2:{
                    total:0,
                    size:10,
                    current:1
                },
                // 分页器数据(人力资源)
                pagination3:{
                    total:0,
                    size:10,
                    current:1
                }
            }
        },
        methods:{
            // 获取表格数据(慕课)
            getTableData1(){
                const {pagination1}=this;
                this.$post("/biz/join/company/pageList",{
                    pageNum:pagination1.current,
                    pageSize:pagination1.size
                }).then(res=>{
                    this.tableData1=res?.data?.list||[];
                    pagination1.total=res?.data?.total||0;
                });
            },
            // （认证）
            getTableData2(){
                const {pagination2}=this;
                this.$post("/biz/cooperate/consulting/pageList",{
                    pageNum:pagination2.current,
                    pageSize:pagination2.size
                }).then(res=>{
                    this.tableData2=res?.data?.list||[];
                    pagination2.total=res?.data?.total||0;
                });
            },
            // （人力资源）
            getTableData3(){
                const {pagination3}=this;
                this.$post("/biz/human/resources/pageList",{
                    pageNum:pagination3.current,
                    pageSize:pagination3.size
                }).then(res=>{
                    this.tableData3=res?.data?.list||[];
                    pagination3.total=res?.data?.total||0;
                });
            },
            // 当前页改变（慕课合作）
            currentChange1(current){
                const {pagination1}=this;
                pagination1.current=current;
                this.getTableData1();
            },
            // 页条数改变
            sizeChange1(size){
                const {pagination1}=this;
                pagination1.size=size;
                this.getTableData1();
            },
            // (认证服务)
            currentChange2(current){
                const {pagination2}=this;
                pagination2.current=current;
                this.getTableData2();
            },
            sizeChange2(size){
                const {pagination2}=this;
                pagination2.size=size;
                this.getTableData2();
            },
            // (人力资源)
            currentChange3(current){
                const {pagination3}=this;
                pagination3.current=current;
                this.getTableData3();
            },
            sizeChange3(size){
                const {pagination3}=this;
                pagination3.size=size;
                this.getTableData3();
            },
            // 获取表格高度
            getTableHeight(opDom = true) {
                let tHeight = window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 4) * 16;
                if (opDom) {
                    tHeight -= 40 + 0.675 * 16 + 1;
                }
                this.tableHeight = tHeight;
            },
        }
    }
</script>

<style lang="less" scoped>
    .pagenum{
        margin-top: 1.875rem;
    }
</style>